import { createContext, useContext, useEffect, useRef } from 'react';
import { ACCESS_TOKEN_EXPIRATION_KEY, ACCESS_TOKEN_KEY, SESSION_EXPIRED_ROUTE_PATH, TOKEN_CHECK_INTERVAL_MS } from "../helpers/constants/constants";

const TokenCheckContext = createContext();

export const TokenCheckContextProvider = ({ children }) => {
    const intervalRef = useRef(null);

    const getTokenExpiration = (token) => {
        try {
            const payload = JSON.parse(atob(token.split('.')[1]));
            return payload.exp * 1000;
        } catch (error) {
            console.error('Invalid token format', error);
            return null;
        }
    };

    const isTokenExpired = (token) => {
        const tokenExpirationTime = getTokenExpiration(token);
        if (!tokenExpirationTime) return true;
        const expirationTime = window.localStorage.getItem(ACCESS_TOKEN_EXPIRATION_KEY);
        return Date.now() >= tokenExpirationTime || Date.now() >= +expirationTime;
    };

    const checkToken = () => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

        if (!accessToken) {
            stopTokenCheck();
        } else if (isTokenExpired(accessToken)) {
            stopTokenCheck();
            window.location.href = SESSION_EXPIRED_ROUTE_PATH;
        }
    };

    const startTokenCheck = () => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(checkToken, TOKEN_CHECK_INTERVAL_MS);
        }
    };

    const stopTokenCheck = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    useEffect(() => {
        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        if (accessToken) {
            startTokenCheck();
        }

        return () => {
            stopTokenCheck();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TokenCheckContext.Provider value={{ startTokenCheck, stopTokenCheck }}>
            {children}
        </TokenCheckContext.Provider>
    );
};

export const useTokenCheck = () => {
    return useContext(TokenCheckContext);
};