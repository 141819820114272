import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { Nav, Navbar } from "react-bootstrap";

import AuthHandler from "../services/common/auth-handler";

import LanguageSelector from "../components/molecules/LanguageSelector";

import {
    DEFAULT_ROUTE,
    USER_REPORTING_ROUTE,
    USER_LANGUAGE_KEY,
    EN_LANGUAGE_KEY,
    ASSET_REPORTING_ROUTE_PATH,
    LOGOUT_ROUTE,
    ASSET_NOT_FOUND_ROUTE_PATH
} from "../helpers/constants/constants";
import Settings from "../components/molecules/Settings";

const authHandler = AuthHandler();
const Header = () => {
    const { t, i18n } = useTranslation('header');
    const location = useLocation();
    const navigate = useNavigate();

    const [showToggle, setShowToggle] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(EN_LANGUAGE_KEY);

    useEffect(() => {
        const language = window.localStorage.getItem(USER_LANGUAGE_KEY);
        if (language) setSelectedLanguage(language);
    }, [])

    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        window.localStorage.setItem(USER_LANGUAGE_KEY, selectedLanguage);
    }, [i18n, selectedLanguage])

    const changeLanguageHandler = (lang) => {
        setSelectedLanguage(lang);
    }

    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        const unsubscribe = authHandler.subscribe(() => {
            setHasAccess(authHandler.hasAssetAccess());
        });

        return () => unsubscribe();
    }, []);

    const toggle = () => {
        if (showToggle) setShowToggle(!showToggle);
    }

    return (
        <Navbar expanded={showToggle} className={`${showToggle ? 'show' : ''} p-2`} expand="lg">
            <div className="brand-container">
                <Navbar.Brand className="d-flex">
                    <Nav.Link
                        onClick={() => { window.location.reload(); }}>
                        <img
                            alt="dcm-logo"
                            role="button"
                            className="px-4"
                            style={{ height: 33 }}
                            src={`${process.env.PUBLIC_URL}/images/logo.svg`} />
                    </Nav.Link>
                </Navbar.Brand>

                {(authHandler.hasTenantAccess() && (location.pathname !== ASSET_REPORTING_ROUTE_PATH && location.pathname !== ASSET_NOT_FOUND_ROUTE_PATH)) &&
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShowToggle(!showToggle)} className="border-0" />}
            </div>

            <Navbar.Collapse className="mt-3 mt-md-0" id="basic-navbar-nav">
                {(authHandler.hasTenantAccess() || hasAccess) &&
                    <>
                        <Nav className="container w-100" style={{ maxWidth: selectedLanguage === EN_LANGUAGE_KEY ? 1220 : 1147 }}>
                            {
                                (location.pathname !== ASSET_REPORTING_ROUTE_PATH && location.pathname !== ASSET_NOT_FOUND_ROUTE_PATH)
                                &&
                                <>
                                    <Nav.Link
                                        eventKey="1"
                                        className={`ps-4 ms-3 ms-md-5 my-4 m-lg-0 ps-lg-0 ${location.pathname === DEFAULT_ROUTE ? 'active' : ''}`}
                                        onClick={() => { navigate('', { replace: false }); toggle(); }}>{t('dam_reporting')}
                                    </Nav.Link>
                                    <Nav.Link
                                        eventKey="2"
                                        className={`ps-4 ms-3 ms-md-5 ms-lg-3 mb-4 mb-lg-0 ${location.pathname === USER_REPORTING_ROUTE ? 'active' : ''}`}
                                        onClick={() => { navigate(USER_REPORTING_ROUTE, { replace: false }); toggle(); }}>{t('user_reporting')}
                                    </Nav.Link>
                                </>
                            }
                        </Nav>

                        <Nav className="d-flex">
                            <Settings
                                minWidth={selectedLanguage === EN_LANGUAGE_KEY ? 67 : 87} />

                            <LanguageSelector
                                selectedLanguage={selectedLanguage}
                                changeLanguageHandler={changeLanguageHandler} />

                            <Nav.Link
                                className="ms-3 px-4 ms-md-5 ms-lg-0 ps-lg-0 d-inline-flex"
                                onClick={() => { navigate(LOGOUT_ROUTE, { replace: false }); toggle(); authHandler.reset(); }}>
                                <span className="d-flex text-nowrap">
                                    <img
                                        alt="sign-out"
                                        src={`${process.env.PUBLIC_URL}/images/icons/login.svg`} />
                                    <span style={{ minWidth: selectedLanguage === EN_LANGUAGE_KEY ? 65 : 114 }}
                                        className="text-regular fw-regular text-highlight ps-2">{t('logout')}
                                    </span>
                                </span>
                            </Nav.Link>
                        </Nav>
                    </>
                }
            </Navbar.Collapse>
        </Navbar >);
}

export default Header;