import { useTranslation } from "react-i18next";

const AssetNotFoundPage = () => {
    const { t } = useTranslation('errors');

    return (
        <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <img
                    alt="page not found"
                    src={`${process.env.PUBLIC_URL}/images/empty/total-asset-performance.svg`} />
                <h1 className="c-dark-blue fw-semi-bold text-center mb-2">{t("analytics_not_found")}</h1>
                <div className="text-center w-75">
                    <span className="c-dark-blue">
                        {t("analytics_not_found_content")}
                    </span>
                </div>
            </div>
        </>);
}

export default AssetNotFoundPage;