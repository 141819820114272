import { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import Footer from "./Footer"
import AuthHandler from "../services/common/auth-handler";
import LoadingPage from "../components/organisms/LoadingPage";

const authHandler = AuthHandler();
const AuthAssetRoute = () => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        authHandler.retrieveAsset().then(access => {
            setHasAccess(access);
            setIsLoading(false);
        });
    }, []);

    return (
        hasAccess
            ? <>
                <Outlet />
                <Footer />
            </>
            : (!isLoading ?
                <Navigate
                    to={{
                        pathname: '/secure',
                        state: { redirectUrl: location.pathname }
                    }}
                />
                : <LoadingPage />
            )
    )
};

export default AuthAssetRoute;