import axios from "axios";
import customSerializer from "../helpers/formatting/customSerializer";

function AssetsService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const get = (assetId) => axios
        .get('/assets', {
            params: {
                assetId: assetId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getCount = (tenantId) => axios
        .get('/assets/count', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getCountByTypes = (tenantId) => axios
        .get('/assets/types/count', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getSize = (tenantId) => axios
        .get('/assets/size', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getAssetEventsCount = (assetId, eventTypes) => axios
        .get(`/assets/${assetId}/events/count`, {
            params: {
                eventTypes: eventTypes
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getEventsCount = (tenantId, eventTypes, includeDeleted = false) => axios
        .get('/assets/events/count', {
            params: {
                tenantId: tenantId,
                eventTypes: eventTypes,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getActivity = (tenantId, from, to, includeDeleted) => axios
        .get('/assets/activity', {
            params: {
                tenantId: tenantId,
                from: from,
                to: to,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                includeDeleted: includeDeleted
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getActivityReport = (tenantId, from, to) => axios
        .get('/assets/activity/report', {
            params: {
                tenantId: tenantId,
                from: from,
                to: to,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const getTypes = (tenantId) => axios
        .get('/assets/types', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    const checkAccess = (assetId) => axios
        .get(`/assets/${assetId}/access`, config())
        .catch(error => error);

    return {
        init,
        dispose,
        get,
        getCount,
        getCountByTypes,
        getSize,
        getAssetEventsCount,
        getEventsCount,
        getActivity,
        getActivityReport,
        getTypes,
        checkAccess
    };
}

export default AssetsService;