import axios from "axios";
import customSerializer from "../helpers/formatting/customSerializer";

function UsersService() {
    let controller = null;
    const init = () => controller = new AbortController();
    const dispose = () => { if (controller) controller.abort(); controller = null; };
    const config = () => controller && controller.signal ? { signal: controller.signal } : null;

    const getActivity = (tenantId, from, to, includeDeleted) => axios
    .get('/users/activity', {
        params: {
            tenantId: tenantId,
            from: from,
            to: to,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            includeDeleted: includeDeleted
        },
        paramsSerializer: params => {
            return customSerializer(params)
        }
    }, config())
    .catch(error => error);

    const getCount = (tenantId) => axios
        .get('/users/count', {
            params: {
                tenantId: tenantId
            },
            paramsSerializer: params => {
                return customSerializer(params)
            }
        }, config())
        .catch(error => error);

    return {
        init,
        dispose,
        getActivity,
        getCount
    };
}

export default UsersService;